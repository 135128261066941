import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["onClick"]

import { defineAsyncComponent, computed } from 'vue'
import ProjIcon from '@/components/proj/ProjIcon/ProjIcon.vue'
import ProjTooltip from '@/components/proj/ProjTooltip/ProjTooltip.vue'
import { dispatchCustomEvent } from '@/utils/dispatchCustomEvent'
import { useRoute } from 'vue-router'
import useWorkspaceStore from '@/stores/workspaceStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBarView',
  setup(__props) {

const GhostMode = defineAsyncComponent(async () => await import('@/components/atoms/GhostMode.vue'))
const WorkspaceIndicator = defineAsyncComponent(async () => await import('@/components/atoms/WorkspaceIndicator.vue'))
const TodoListPartial = defineAsyncComponent(async () => await import('@/components/atoms/TodoListPartial.vue'))

const route = useRoute()
const workspaceStore = useWorkspaceStore()

function removePathNumbers (path: string): string {
  if (path === '/' || (path.split('/').length > 2)) return path
  return path.replace(/\/[^/]+$/, '')
}

const currentPath = computed(() => {
  return removePathNumbers(route.path)
})

const itemsList = [{
  key: 'dashboard',
  path: '/',
  icon: 'chart-line',
  family: 'regular',
  tooltipText: 'Dashboard',
  isActive: ['RECOVERY', 'PROCURADORIA', 'PROCON_CONSUMIDOR', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'negotiation',
  path: '/negotiation',
  icon: 'comments',
  family: 'solid',
  tooltipText: 'Negociação',
  isActive: ['RECOVERY', 'PROCURADORIA', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'management',
  path: '/management',
  icon: 'list-check',
  family: 'regular',
  tooltipText: 'Gerenciamento',
  isActive: ['RECOVERY', 'PROCURADORIA', 'PROCON_CONSUMIDOR', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'import',
  path: '/import',
  icon: 'file-import',
  family: 'regular',
  tooltipText: 'Importação',
  isActive: ['RECOVERY', 'PROCURADORIA', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}].filter(item => item.isActive)

function handleClick (path: string): void {
  dispatchCustomEvent('sideMenuRouting', { path })
}

return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "sidebar-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_aside, {
        class: "aside-container",
        width: "100%"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(GhostMode)),
          _createVNode(_unref(WorkspaceIndicator)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(itemsList), (item) => {
            return (_openBlock(), _createBlock(ProjTooltip, {
              key: item.key,
              content: item.tooltipText,
              effect: "dark",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["sidebar-container__item", { 'sidebar-container__item--active': currentPath.value === item.path }]),
                  onClick: ($event: any) => (handleClick(item.path))
                }, [
                  _createVNode(ProjIcon, {
                    icon: item.icon,
                    family: item.family
                  }, null, 8, ["icon", "family"])
                ], 10, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["content"]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_unref(TodoListPartial))
    ]),
    _: 1
  }))
}
}

})