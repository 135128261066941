import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'ProjIcon',
  inheritAttrs: false
},
  __name: 'ProjIcon',
  props: /*@__PURE__*/_mergeModels({
  alt: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '24px'
  }
}, {
    "icon": {
  type: String,
  required: true
},
    "iconModifiers": {},
    "isActive": {
  type: Boolean,
  default: false
},
    "isActiveModifiers": {},
    "isWhite": {
  type: Boolean,
  default: false
},
    "isWhiteModifiers": {},
    "family": {
  type: String,
  default: 'default'
},
    "familyModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels([
  'click',
  'hover'
], ["update:icon", "update:isActive", "update:isWhite", "update:family"]),
  setup(__props) {





const icon = _useModel(__props, 'icon')
const isActive = _useModel(__props, 'isActive')
const isWhite = _useModel(__props, 'isWhite')
const family = _useModel(__props, 'family')



const src = computed((): string => {
  const iconsBucketUrl = 'https://assets.acordo.app'
  const sufix = (isActive.value ? '-active' : '') + (isWhite.value ? '-white' : '')
  if (icon.value.length > 0) {
    if (family.value !== 'default') {
      return `${iconsBucketUrl}/projuris/svgs/${family.value}/${icon.value}.svg`
    }

    return `${iconsBucketUrl}/icons/ic-${icon.value}${sufix}.svg`
  }

  return ''
})

return (_ctx: any,_cache: any) => {
  return (icon.value)
    ? (_openBlock(), _createElementBlock("img", _mergeProps({ key: 0 }, _ctx.$attrs, {
        src: src.value,
        alt: __props.alt,
        style: { width: __props.size, display: 'inline' },
        draggable: "false",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('hover')))
      }), null, 16, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})