<template>
  <img
    v-if="icon"
    v-bind="$attrs"
    :src="src"
    :alt="alt"
    :style="{ width: size, display: 'inline' }"
    draggable="false"
    @click="$emit('click')"
    @mouseover="$emit('hover')"
  >
</template>

<script lang="ts" setup>
import { computed } from 'vue'

defineOptions({
  name: 'ProjIcon',
  inheritAttrs: false
})

defineProps({
  alt: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '24px'
  }
})

const icon = defineModel('icon', {
  type: String,
  required: true
})
const isActive = defineModel('isActive', {
  type: Boolean,
  default: false
})
const isWhite = defineModel('isWhite', {
  type: Boolean,
  default: false
})
const family = defineModel('family', {
  type: String,
  default: 'default'
})

defineEmits([
  'click',
  'hover'
])

const src = computed((): string => {
  const iconsBucketUrl = 'https://assets.acordo.app'
  const sufix = (isActive.value ? '-active' : '') + (isWhite.value ? '-white' : '')
  if (icon.value.length > 0) {
    if (family.value !== 'default') {
      return `${iconsBucketUrl}/projuris/svgs/${family.value}/${icon.value}.svg`
    }

    return `${iconsBucketUrl}/icons/ic-${icon.value}${sufix}.svg`
  }

  return ''
})
</script>
