import 'systemjs-webpack-interop/auto-public-path/auto-public-path'
import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import type { ParcelConfigObject } from 'single-spa'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/src/index.scss'
import '@/main.scss'

import App from './App.vue'
import pinia from './stores'
import router from './router'
import i18n from './locales'

interface LifecycleProps {
  mountParcel: (parcelConfig: ParcelConfigObject, customProps: object) => any
  unmount: () => Promise<any>
}

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {})
    }
  },
  handleInstance (app) {
    app
      .use(pinia)
      .use(router)
      .use(i18n)
      .use(ElementPlus)
  }
})

export let mountParcel: (parcelConfig: ParcelConfigObject, customProps: object) => any
export let unmountParcel: () => Promise<any>
export const bootstrap = [
  async (props: LifecycleProps) => {
    mountParcel = props.mountParcel
    unmountParcel = props.unmount
    return await Promise.resolve()
  },
  vueLifecycles.bootstrap
]

export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
