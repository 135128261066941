import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ElTooltip } from 'element-plus'
import type { PropType } from 'vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'ProjTooltip',
  inheritAttrs: false
},
  __name: 'ProjTooltip',
  props: {
  // appendTo: {

  // },
  effect: {
    type: String as PropType<'dark' | 'light'>,
    default: 'dark'
  },
  content: {
    type: String,
    default: ''
  },
  rawContent: {
    type: Boolean,
    default: false
  },
  placement: {
    type: String as PropType<'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end'>,
    default: 'bottom'
  },
  fallbackPlacements: {
    type: Array as PropType<Array<'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end'>>,
    default: () => []
  },
  disabled: {
    type: Boolean
  },
  offset: {
    type: Number,
    default: 12
  },
  transition: {
    type: String,
    default: 'el-fade-in-linear'
  },
  showAfter: {
    type: Number,
    default: 0
  },
  showArrow: {
    type: Boolean,
    default: true
  },
  hideAfter: {
    type: Number,
    default: 200
  },
  autoClose: {
    type: Number,
    default: 0
  },
  popperClass: {
    type: String,
    default: 'proj-tooltip#pmenu'
  },
  enterable: {
    type: Boolean,
    default: true
  },
  teleported: {
    type: Boolean,
    default: true
  },
  trigger: {
    type: String as PropType<'hover' | 'click' | 'focus' | 'contextmenu'>,
    default: 'hover'
  },
  virtualTriggering: {
    type: Boolean
  },
  triggerKeys: {
    type: Array as PropType<Array<'Enter' | 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight' | 'Space'>>,
    default: () => ['Enter', 'Space']
  },
  persistent: {
    type: Boolean
  },
  ariaLabel: {
    type: String,
    default: ''
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ElTooltip), _mergeProps(_ctx.$attrs, {
    effect: __props.effect,
    content: __props.content,
    "raw-content": __props.rawContent,
    placement: __props.placement,
    "fallback-placements": __props.fallbackPlacements,
    disabled: __props.disabled,
    offset: __props.offset,
    transition: __props.transition,
    "show-after": __props.showAfter,
    "show-arrow": __props.showArrow,
    "hide-after": __props.hideAfter,
    "auto-close": __props.autoClose,
    "popper-class": __props.popperClass,
    enterable: __props.enterable,
    teleported: __props.teleported,
    trigger: __props.trigger,
    "virtual-triggering": __props.virtualTriggering,
    "trigger-keys": __props.triggerKeys,
    persistent: __props.persistent,
    "aria-label": __props.ariaLabel
  }), {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["effect", "content", "raw-content", "placement", "fallback-placements", "disabled", "offset", "transition", "show-after", "show-arrow", "hide-after", "auto-close", "popper-class", "enterable", "teleported", "trigger", "virtual-triggering", "trigger-keys", "persistent", "aria-label"]))
}
}

})