<template>
  <el-container class="sidebar-container">
    <el-aside
      class="aside-container"
      width="100%"
    >
      <GhostMode />

      <WorkspaceIndicator />

      <ProjTooltip
        v-for="item in itemsList"
        :key="item.key"
        :content="item.tooltipText"
        effect="dark"
        placement="right"
      >
        <div
          class="sidebar-container__item"
          :class="{ 'sidebar-container__item--active': currentPath === item.path }"
          @click="handleClick(item.path)"
        >
          <ProjIcon
            :icon="item.icon"
            :family="item.family"
          />
        </div>
      </ProjTooltip>
    </el-aside>

    <TodoListPartial />
  </el-container>
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue'
import ProjIcon from '@/components/proj/ProjIcon/ProjIcon.vue'
import ProjTooltip from '@/components/proj/ProjTooltip/ProjTooltip.vue'
import { dispatchCustomEvent } from '@/utils/dispatchCustomEvent'
import { useRoute } from 'vue-router'
import useWorkspaceStore from '@/stores/workspaceStore'

const GhostMode = defineAsyncComponent(async () => await import('@/components/atoms/GhostMode.vue'))
const WorkspaceIndicator = defineAsyncComponent(async () => await import('@/components/atoms/WorkspaceIndicator.vue'))
const TodoListPartial = defineAsyncComponent(async () => await import('@/components/atoms/TodoListPartial.vue'))

const route = useRoute()
const workspaceStore = useWorkspaceStore()

function removePathNumbers (path: string): string {
  if (path === '/' || (path.split('/').length > 2)) return path
  return path.replace(/\/[^/]+$/, '')
}

const currentPath = computed(() => {
  return removePathNumbers(route.path)
})

const itemsList = [{
  key: 'dashboard',
  path: '/',
  icon: 'chart-line',
  family: 'regular',
  tooltipText: 'Dashboard',
  isActive: ['RECOVERY', 'PROCURADORIA', 'PROCON_CONSUMIDOR', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'negotiation',
  path: '/negotiation',
  icon: 'comments',
  family: 'solid',
  tooltipText: 'Negociação',
  isActive: ['RECOVERY', 'PROCURADORIA', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'management',
  path: '/management',
  icon: 'list-check',
  family: 'regular',
  tooltipText: 'Gerenciamento',
  isActive: ['RECOVERY', 'PROCURADORIA', 'PROCON_CONSUMIDOR', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}, {
  key: 'import',
  path: '/import',
  icon: 'file-import',
  family: 'regular',
  tooltipText: 'Importação',
  isActive: ['RECOVERY', 'PROCURADORIA', 'INDENIZATORIO'].includes(workspaceStore.getWorkspaceType)
}].filter(item => item.isActive)

function handleClick (path: string): void {
  dispatchCustomEvent('sideMenuRouting', { path })
}
</script>

<style lang="scss">
.sidebar-container {
  background: #0756E4;
  height: 100vh;
  width: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .aside-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .sidebar-container__item {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &.sidebar-container__item--active {
        background: rgba(255, 255, 255, 0.2);
      }

      img {
        filter: invert(1);
        width: 20px;
        height: 20px;
      }
    }

  }

}
</style>
